import React from 'react'
import {
  Modal,
  Grid,
  IconButton,
  Typography,
  Button,
  Stack,
  useMediaQuery,
} from '@mui/material'
import closeicon from '@meprism/shared/src/assets/icons/successclose.png'
import { theme } from '../../../config/Dashboardtheme'

interface GenericModalProps {
  open: boolean
  onClose: () => void
  title: string
  message: string
  confirmText?: string
  cancelText?: string
  confirmingText?: string
  onConfirm?: () => void
  confirmDisabled?: boolean
  children?: React.ReactNode
}

const GenericModal: React.FC<GenericModalProps> = ({
  open,
  onClose,
  title,
  message,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  confirmDisabled = false,
  children,
  confirmingText = 'Confirming'
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Modal
      open={open}
      sx={{
        backdropFilter: 'blur(5px)',
        background: 'rgba(0, 0, 0, 0.01)',
      }}
      onClose={onClose}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          outline: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxWidth: '600px',
          transform: 'translate(-50%, -50%)',
          width: !isMobile ? '30%' : '80%',
          background: '#fff',
          p: 10,
          borderRadius: '10px',
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: -60,
            right: 10,
            zIndex: 1,
            color: '#ffffff',
          }}
        >
          <img src={closeicon} style={{ width: '42px' }} />
        </IconButton>

        <Stack spacing={2} flexDirection={'column'}>
          <Typography variant="h2" mb={2} textAlign="center">
            {title}
          </Typography>
          <Typography variant="body2" mb={2} textAlign="center" color={'#615F6B'}>
            {message}
          </Typography>
          {children}
        </Stack>

        <Grid container spacing={2} mt={5}>
          <Grid item xs={6} sm={6}>
            <Button
              variant={'contained'}
              color={'darkPurple'}
              onClick={onClose}
              sx={{
                width: '100%',
                borderRadius: '10px',
                boxShadow: 'unset',
                border: '2px solid #C67EFF',
                background: 'transparent',
                color: '#C67EFF',
                '&.Mui-disabled': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
                ':hover': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
              }}
            >
              {cancelText}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              variant={'outlined'}
              color={'darkPurple'}
              onClick={onConfirm}
              disabled={confirmDisabled}
              sx={{
                width: '100%',
                borderRadius: '10px',
                boxShadow: 'unset',
                p: 2,
                '&.Mui-disabled': {
                  color: '#a1a1a1',
                },
              }}
            >
              { confirmDisabled ? confirmingText : confirmText }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default GenericModal
