import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import {
  AuthenticationActions,
  getPreferredMfa,
  selectAccountEmail,
  selectMuid,
  selectPreferredMfa,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'

import { LoaderFunction, useNavigate } from 'react-router-dom'
import { store } from '../../../redux/store'
import { MfaCard } from '../../Authentication/MfaCard'
import {
  getCurrentAuthenticatedUser,
  setPreferredMfa,
  updateUserAttributes,
} from '@meprism/shared/src/services/AuthenticationService'
import { Toast } from '../../toast/toast'
import { AuthContext } from '../../Authentication/AuthContext'
import MainHeader from '../../shared/organism/MainHeader'
import GenericModal from '../../shared/atom/GenericModal'
import AddAdminModal from '../../shared/organism/AddAdminModal'
import {
  CorporateProfileSelector,
  getCorporateProfile
} from '../../../redux/CorporateProfile/CorporateProfileSlice'
import { API } from 'aws-amplify'

const styles = {
  card: {
    height: '80vh',
    borderRadius: 5,
    marginBlock: '0.8rem',
  },
  accountcard: { borderRadius: 3, background: '#f6f6f6' },
  copybutton: {
    cursor: 'pointer',
    color: '#426bf0',
  },
  fontBold: {
    fontFamily: 'InterSemiBold',
    color: 'black',
  },
  fontGray: {
    color: '#767676',
  },
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  marginInline: 10,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export const accountLoader: LoaderFunction = async () => {
  // note that NOT unwrapping this is intentional, if we can't get their MFA, then we'll render the
  // screen as though it's not set
  return store.dispatch(getPreferredMfa()).unwrap()
}

const formatPhone = (phone: string) => `+1${phone.replace(/[^\d]/g, '')}`
const validatePhone = (phone: string) => phone.match(/\+1\d{10}/)

interface User {
  buid: string
  email: string
}

const Account = () => {
  const authContext = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const accountEmail = useAppSelector(selectAccountEmail)
  const muid = useAppSelector(selectMuid)
  const [phone, setPhone] = useState('')
  const [modalState, setModalState] = useState({
    adminModal: false,
    confirmationModal: false,
    deleting: false,
    selectedUser: null as User | null,
  })
  const { draftPreferredMfa, registeredPreferredMfa } =
    useAppSelector(selectPreferredMfa)
  const enableTwoFactor = draftPreferredMfa !== 'NOMFA'
  const [corporateProfile, setCorporateProfile] = useState(
    CorporateProfileSelector(),
  )

  if (!corporateProfile) {
    return (
      <Typography variant="h4">
        Company Info could not be fetch. Please try again later.
      </Typography>
    )
  }

  const disableAddAdmin = corporateProfile.associatedProfiles
  ? corporateProfile.associatedProfiles.length > 3
  : false

  const onTwoFactorSubmit = async () => {
    authContext.setUser(await getCurrentAuthenticatedUser())
    if (draftPreferredMfa === 'SMS_MFA') {
      const formattedPhone = formatPhone(phone)
      if (!validatePhone(formattedPhone)) {
        Toast.show({
          type: 'error',
          text1:
            'A valid phone number is required when using SMS 2 factor authentication',
        })
        return
      }
      await updateUserAttributes({ phone_number: formattedPhone })
      await setPreferredMfa('SMS_MFA')
    } else if (draftPreferredMfa === 'SOFTWARE_TOKEN_MFA') {
      navigate('/totp', { state: { from: location.pathname } })
    } else {
      setPreferredMfa('NOMFA').then(() => {
        store.dispatch(getPreferredMfa())
        Toast.show({
          type: 'success',
          text1: 'Your MFA settings have been saved',
        })
      })
    }
  }

  const openModal = (modal: 'adminModal' | 'confirmationModal', user?: User) => {
    setModalState((prev) => ({
      ...prev,
      [modal]: true,
      selectedUser: user || prev.selectedUser,
    }))
  }

  const closeModal = (modal: 'adminModal' | 'confirmationModal') => {
    setModalState((prev) => ({
      ...prev,
      [modal]: false,
      ...(modal === 'confirmationModal' && { selectedUser: null }),
    }))
  }

  const onDelete = async () => {
    const { selectedUser } = modalState
    if (!selectedUser) return

    setModalState((prev) => ({ ...prev, deleting: true }))
    try {
      const code = await API.get('Corporate', '/codes', {})
      await API.del('Corporate', `/codes/${code.code}/admin_users`, {
        body: {
          buid: selectedUser.buid,
          email: selectedUser.email,
        },
      })

      fetchUpdatedProfile()
      Toast.show({ type: 'success', text1: 'Admin successfully removed!' })
    } catch (error) {
      console.error('Error removing admin:', error)
      Toast.show({ type: 'error', text1: 'Failed to remove admin. Please try again.' })
    } finally {
      setModalState((prev) => ({ ...prev, deleting: false }))
      closeModal('confirmationModal')
    }
  }

  const fetchUpdatedProfile = async () => {
    try {
      const updatedProfile = await dispatch(getCorporateProfile()).unwrap()
      setCorporateProfile(updatedProfile)
    } catch (error) {
      console.error('Error fetching corporate profile:', error)
    }
  }

  return (
    <Container fixed maxWidth={'lg'} sx={{ mb: 16, mt: '12px' }}>
      <MainHeader title="Settings" />
      <Card sx={styles.card}>
        <CardContent>
          <Grid
            container
            columnSpacing={5}
            rowSpacing={5}
            sx={{ marginBlockEnd: 5 }}>
            <Grid item xs={12} md={12}>
              <Typography variant="h3">Login & Security</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body3">
                Your mePrism account access & password management
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={styles.accountcard}>
                <CardContent>
                  <Typography variant="subtitle2" sx={styles.fontBold}>
                    Sign in Email
                  </Typography>
                  <Box sx={{ paddingBlockStart: '0.5rem' }}>
                    <Typography variant="caption" sx={styles.fontGray}>
                      {accountEmail}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={styles.accountcard}>
                <CardContent>
                  <Grid container columnSpacing={5}>
                    <Grid item md={10}>
                      <Typography variant="subtitle2" sx={styles.fontBold}>
                        mePrism ID
                      </Typography>
                      <Box sx={{ paddingBlockStart: '0.5rem' }}>
                        <Typography variant="caption" sx={styles.fontGray}>
                          {muid}{' '}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'right' }}>
                      <Typography
                        variant="caption"
                        onClick={() =>
                          navigator.clipboard.writeText(muid as string)
                        }
                        sx={styles.copybutton}>
                        Copy
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            columnSpacing={5}
            rowSpacing={5}
            sx={{ marginBlock: 2 }}
          >
            <Grid item xs={12} md={6}>
              <Grid container item xs={12} md={12} alignItems="center" justifyContent="space-between">
                <Typography variant="h3">Business Admin User Management</Typography>
                <Typography variant="body3">
                  You can add upto 4 admins to your mePrism account
                </Typography>

                <Tooltip title={disableAddAdmin ? 'You have reached the maximum limit for admin users' : ''}>
                  <span>
                    <IconButton
                      onClick={() => openModal('adminModal')}
                      disabled={disableAddAdmin}
                      sx={{
                        backgroundColor: disableAddAdmin ? 'grey.400' : 'primary.main',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: disableAddAdmin ? 'grey.400' : '#426bf0',
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <List dense={true}>
                    {corporateProfile.associatedProfiles?.map((user, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => openModal('confirmationModal', { buid: user.buid, email: user.email })}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                        sx={{ marginBottom: 2 }}
                      >
                        <ListItemText primary={user.email} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3">Account Security</Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={enableTwoFactor}
                    onChange={(e) =>
                      dispatch(
                        AuthenticationActions.setDraftMfa(
                          e.target.checked ? 'SOFTWARE_TOKEN_MFA' : 'NOMFA',
                        ),
                      )
                    }
                  />
                }
                label="Two Factor Authentication"
              />
              {enableTwoFactor && (
                <Box sx={{ marginBlock: '1rem' }}>
                  <MfaCard phone={phone} setPhone={setPhone} />
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        onClick={onTwoFactorSubmit}
        disabled={draftPreferredMfa === registeredPreferredMfa}>
        Save Changes
      </Button>
      <GenericModal
        open={modalState.confirmationModal}
        onClose={() => closeModal('confirmationModal')}
        title="Are you sure?"
        message="Do you really want to remove this user? This action cannot be undone."
        confirmText="Delete"
        confirmingText='Deleting..'
        cancelText="Cancel"
        onConfirm={onDelete}
        confirmDisabled={modalState.deleting}
      />
      <AddAdminModal
        open={modalState.adminModal}
        onClose={() => closeModal('adminModal')}
        updateProfile={fetchUpdatedProfile}
      />
    </Container>
  )
}

export default Account
