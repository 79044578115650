import {
  CognitoHostedUIIdentityProvider,
  CognitoUser,
  SignUpParams,
} from '@aws-amplify/auth'
import { Logger } from '@meprism/app-utils'
import { Auth } from 'aws-amplify'
import { AnalyticsService } from './AnalyticsService'
import { NavigateFunction, useLocation } from 'react-router-dom'
import { AuthContextType } from '../components/Authentication/AuthContext'
import {
  handleOtpNavigation,
  OtpRouteParam,
} from '../components/Authentication/LoginOTP'
import { startAppListening } from '../redux/listenerMiddleware'
import { postConfirmLogin } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import Cookies from 'universal-cookie'

export interface UserInfo {
  username: string
  muid: string
}

export interface AuthUser {
  userInfo: UserInfo
}

const loggingPrefix = 'Auth-Service: '

export const buildAuthUser = (authData: Record<string, any>): AuthUser => {
  const userInfo = {
    username: authData?.username,
    muid: authData?.attributes?.sub,
  }

  return { userInfo }
}

export const getAuthenticatedUser = async (): Promise<AuthUser> => {
  let authUser = {} as AuthUser
  try {
    Logger.debug(loggingPrefix + 'Attempting to get Authenticated user info')
    authUser = buildAuthUser(await Auth.currentAuthenticatedUser())
  } catch (error) {
    Logger.debug(loggingPrefix + 'Get Authenticated User Info Failed ' + error)
  }
  return authUser
}

export const getUserAuthToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken()
}

export const signInWithApple = async (destination = '/') => {
  try {
    Logger.debug(loggingPrefix + 'Attempting to Sign In With Apple')
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple,
      customState: destination,
    })
  } catch (error) {
    Logger.debug(loggingPrefix + 'Sign In With Apple Failed ' + error)
  }
}

export const signInWithGoogle = async (destination = '/') => {
  try {
    Logger.debug(loggingPrefix + 'Attempting to Sign In With Google')
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: destination,
    })
  } catch (error) {
    Logger.debug(loggingPrefix + 'Sign In With Google Failed ' + error)
  }
}

export const signOut = async () => {
  try {
    Logger.debug(loggingPrefix + 'Attempting to Sign Out')
    await Auth.signOut()
    AnalyticsService.reset()
  } catch (error) {
    Logger.info(loggingPrefix + 'error signing out: ' + error)
  }
}

export const signInWithEmail = async (username: string, password: string) => {
  try {
    return await Auth.signIn(username, password)
  } catch (error) {
    Logger.info(loggingPrefix + 'error signing in : ' + error)
    throw error
  }
}

export const signUpWithEmail = async (params: SignUpParams) => {
  try {
    return await Auth.signUp(params)
  } catch (error) {
    Logger.debug(loggingPrefix + `error signing up : ${error}`)
    throw error
  }
}

export const confirmSignUp = async (username: string, otp: string) => {
  Logger.debug(`${loggingPrefix} Confirming signup for ${username}`)
  try {
    return await Auth.confirmSignUp(username, otp)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error confirming signup ${error}`)
    throw error
  }
}

export const forgotPassword = async (username: string) => {
  Logger.debug(`Resetting password for ${username}`)
  try {
    await Auth.forgotPassword(username)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error confirming signup ${error}`)
    throw error
  }
}

export const submitForgotPassword = async (
  username: string,
  otp: string,
  newPassword: string,
) => {
  Logger.debug(`${loggingPrefix} Submitting reset password for ${username}`)
  try {
    await Auth.forgotPasswordSubmit(username, otp, newPassword)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error confirming forgot password ${error}`)
    throw error
  }
}

export const resendSignup = async (username: string) => {
  try {
    Logger.info(`${loggingPrefix} Resending signup for ${username}`)
    await Auth.resendSignUp(username)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error resending signup ${error}`)
    throw error
  }
}

type HandleAuthChallengeProps = {
  navigate: NavigateFunction
  user: CognitoUser
  setUser: AuthContextType['setUser']
  location: ReturnType<typeof useLocation> | undefined
}

export const handleAuthChallenge = async ({
  navigate,
  user,
  setUser,
  location,
}: HandleAuthChallengeProps) => {
  if (!user.challengeName) {
    return
  }
  setUser(user)
  switch (user.challengeName) {
    case 'NEW_PASSWORD_REQUIRED':
      navigate('/completePassword', {
        replace: true,
        state: { ...location?.state, isUser: true },
      })
      break
    case 'MFA_SETUP':
      navigate('/totp', {
        replace: true,
      })
      break
    case 'SMS_MFA':
      // aws doesn't know its own types, which is frustrating, anyway ?. makes it safe
      const potentialPhone: string | undefined = (user as any)?.challengeParam
        ?.CODE_DELIVERY_DESTINATION
      const destination = potentialPhone
        ? ` the number ending in ${potentialPhone.replaceAll(/[\D]/g, '')}`
        : ' your phone'
      const state: OtpRouteParam = {
        actionType: 'SIGNIN_SMS_MFA',
        destination: destination,
      }
      handleOtpNavigation(navigate, state)
      break
    case 'SOFTWARE_TOKEN_MFA':
      const otpState: OtpRouteParam = {
        actionType: 'SIGNIN_SOFTWARE_TOKEN_MFA',
        destination: 'your authenticator app',
      }
      handleOtpNavigation(navigate, otpState)
      break
    default:
      Logger.error(
        `Unhandled user authentication challenge: ${user.challengeName}`,
      )
      navigate('/login', { replace: true })
      break
  }
}

export const getBuidFromToken = async (): Promise<string | undefined> => {
  const idToken = (await Auth.currentSession()).getIdToken()
  return idToken.payload['custom:buid']
}

startAppListening({
  actionCreator: postConfirmLogin.fulfilled,
  effect: async (_, { extra }) => {
    const cookies = new Cookies()
    try {
      const gaid = cookies.get('_ga')
      if (gaid) {
        extra.AnalyticsManager.union('gaid', gaid)
      }
    } catch (error) {
      // nothing to do here, don't want to interfere
      // could error if ex user blocked access to cookie
    }
  },
})
