import React from 'react'
import { useAppDispatch } from '../../../redux/storeExports'
import { fetchUsers, removeUser } from '../../../redux/corporateService'
import GenericModal from './GenericModal'
interface SuccessLoginModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  muid: any
}

const ConfirmationModal = (props: SuccessLoginModalProps) => {
  const dispatch = useAppDispatch()
  const onDelete = async () => {
    props.setOpen(false)
    await dispatch(removeUser(props.muid as string)).unwrap()
    dispatch(fetchUsers())
  }
  const handleClose = () => {
    props.setOpen(false)
  }
  return (
    <GenericModal
      open={props.open}
      onClose={handleClose}
      title="Are you sure?"
      message="Do you really want to remove? This action cannot be undone."
      confirmText="Delete"
      cancelText="Cancel"
      onConfirm={onDelete}
    />
  )
}

export default ConfirmationModal
