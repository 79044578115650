import React, { useState } from 'react'
import {
  Modal,
  Grid,
  IconButton,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  Box,
  Input,
} from '@mui/material'
import closeicon from '@meprism/shared/src/assets/icons/successclose.png'
import { theme, fonts } from '../../../config/Dashboardtheme'
import { API } from 'aws-amplify'
import { Toast } from '../../toast/toast'
import { isValidEmail } from '../../../utils/helpers'

interface AddAdminModalProps {
  open: boolean
  onClose: () => void
  updateProfile: () => void
}

const AddAdminModal: React.FC<AddAdminModalProps> = ({
  open,
  onClose,
  updateProfile,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [emailState, setEmailState] = useState({
    email: '',
    isEmailValid: false,
    isSaving: false,
  })

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setEmailState((prevState) => ({
      ...prevState,
      email: value,
      isEmailValid: isValidEmail(value),
    }))
  }

  const handleSave = async () => {
    setEmailState((prevState) => ({ ...prevState, isSaving: true }))
    try {
      const code = await API.get('Corporate', '/codes', {})
      await API.post('Corporate', `/codes/${code.code}/admin_users`, { body: emailState.email })
      updateProfile()
      Toast.show({
        type: 'success',
        text1: 'Invite sent to admin.',
      })
    } catch (error) {
      console.error('Failed to send invite:', error)
      const errorMessage =
        error.response?.data?.error?.includes('UsernameExistsException')
          ? 'An admin with this account already has a mePrism account.'
          : 'There was an error adding admin, please try again.'
      Toast.show({
        type: 'error',
        text1: errorMessage,
      })
    } finally {
      setEmailState({ email: '', isEmailValid: false, isSaving: false })
      onClose()
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        backdropFilter: 'blur(5px)',
        background: 'rgba(0, 0, 0, 0.01)',
      }}
      onClose={onClose}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          outline: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxWidth: '600px',
          transform: 'translate(-50%, -50%)',
          width: !isMobile ? '40%' : '80%',
          background: '#fff',
          p: 10,
          borderRadius: '10px',
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: -60,
            right: 10,
            zIndex: 1,
            color: '#ffffff',
          }}
        >
          <img src={closeicon} style={{ width: '42px' }} />
        </IconButton>

        <Stack spacing={2} flexDirection={'column'}>
          <Typography variant="h2" mb={2} textAlign="center">
            Invite Admin
          </Typography>
          <Typography variant="body2" mb={2} textAlign="center" color={'#615F6B'}>
            Enter email of an admin to send out an invite.
          </Typography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              marginBottom: '10px',
              background: '#FFFFFF',
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: '100%', flex: 1 }}>
              <Input
                value={emailState.email}
                onChange={handleEmailChange}
                placeholder={'admin@meprism.com'}
                sx={{
                  '&.MuiInputBase-root': {
                    '&.MuiInput-root::before': {
                      content: 'none',
                    },
                  },
                  borderRadius: '5px',
                  border: '1px solid #A0A0A4',
                  p: 3,
                  flex: 1,
                  fontFamily: fonts.Inter[300],
                  fontSize: '16px',
                  width: '100%',
                }}
              />
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2} mt={5}>
          <Grid item xs={6} sm={6}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleSave}
              disabled={!emailState.isEmailValid || emailState.isSaving}
              sx={{
                width: '100%',
                borderRadius: '10px',
                boxShadow: 'unset',
                p: 2,
                '&.Mui-disabled': {
                  color: '#a1a1a1',
                },
              }}
            >
              {emailState.isSaving ? 'Inviting...' : 'Save & Continue'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default AddAdminModal
